import React from 'react'
import { 
	graphql, 
	// Link 
} from "gatsby"
import { buildFluidImageData } from '@imgix/gatsby'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
// import { faEdit } from '@fortawesome/pro-light-svg-icons'

// Layout
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Matrix from "../components/Matrix"

// Tailwind React UI Components
import { 
	Container,
	Section
} from 'tailwind-react-ui'

// Custom Components
import GreenCarousel from '../components/Matrix/types/Independent/GreenCarousel'
import PageBanner from '../components/PageBanner'

// GraphQL
export const query = graphql`
	query ClassInTheCloudsQuery {
		entry: craftClassInTheCloudsClassInTheCloudsEntry {
			id
			remoteId
			subtitle
			title
			typeHandle
			uri
			url
			featureImage { ...UploadAssetFragment }
			metaTitle
			metaDescription
			metaKeywords
			metaImage { ...UploadAssetFragment }
			summary
			bodyContent {
				... BodyContentFragment
			}
			greenContentSlider {
				... on Craft_greenContentSlider_slide_BlockType {
					heading
					image { ...UploadAssetFragment }
					text
					download {
						title
						filename
						size
						url
					}
					buttonText
					buttonUrl
					relatedEntry { uri }
					warningText
				}
			}
		}
		siteInfo: craftSiteInformationGlobalSet {
			...SiteInfoFragment
		}
	}
`

const truncateString = (string = '', maxLength = 54) => 
  string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string

const ClassInTheClouds = ({ data: { entry, siteInfo } }) => {
	const subtitle = entry.subtitle || siteInfo.siteTitle
	const [ siteImage ]  = siteInfo.siteImage
	const [ featureImage = siteImage ] = entry.featureImage
	const metaImage = featureImage || entry.metaImage || siteInfo.siteImage
	const metaDescription =  truncateString((entry.metaDescription || entry.summary || siteInfo.siteDescription).replace(/(<([^>]+)>)/gi, ""), 159)
	const metaTitle = truncateString(`${entry.metaTitle || entry.displayTitle || entry.title}`, 53)
	const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null

	const metaImageFormatted = buildFluidImageData(
		`${metaImage.url}`,
		{
			ar: 1, // required
			auto: ['format', 'compress'],
			sizes: `300px, 300px`, // optional, but highly recommended
			width: 300,
			height: 300
		}
    )

	return (
		<Layout location={entry.url} title={metaTitle}>
			<Seo
				title       = {metaTitle}
				description = {metaDescription}
				keywords    = {metaKeywords}
				image       = {metaImageFormatted.src}
				url         = {entry.url}
				pathname	= {`/${entry.uri}`}
			/>
			<h1 style={{fontSize: 0, color: 'transparent'}} className="sr-only">{metaTitle}</h1>

			<Section p="0" className="relative">
				<PageBanner
					title    = {entry.title}
					subtitle = {subtitle}
					image    = {featureImage}
				/>
			</Section>	

			{entry.bodyContent.length ? <Section p="0" className="secondary-page relative">
				<Container className="pt-8 pb-14 xl:w-3/4">
					<Matrix blocks={entry.bodyContent} />
				</Container>
			</Section>: '' }

			<Section bg="gray-light"  p="0">
				<Container className="xl:w-3/4 py-14 lg:py-20">
					{entry.greenContentSlider ?
						<GreenCarousel
							items={entry.greenContentSlider}
						/>
					: null}
				</Container>
			</Section>
		</Layout>
	)
}

export default ClassInTheClouds